<header class="full-width" [ngStyle]="{ 'background-color': Header_Bg_Color }">
  <nav class="navbar navbar-expand-lg navbar-light grey-bg">
    <div class="row full-width">
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="social">
          <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a
                *ngIf="link.value != null"
                class="btn-floating rgba-white-slight"
                href="{{ link.value }}"
                target="_blank"
              >
                <i
                  *ngIf="link.type.name == 'whatsapp'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-whatsapp"
                ></i>
                <i
                  *ngIf="link.type.name == 'facebook'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-facebook"
                ></i>
                <i
                  *ngIf="link.type.name == 'twitter'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-twitter"
                ></i>
                <i
                  *ngIf="link.type.name == 'youtube'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-youtube"
                  style="padding: 10px 8px !important"
                ></i>
                <i
                  *ngIf="link.type.name == 'instagram'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-instagram"
                ></i>
                <i
                  *ngIf="link.type.name == 'snapchat'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fa fa-snapchat"
                ></i>
                <i
                  *ngIf="link.type.name == 'telegram'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-telegram-plane"
                ></i>
                <i
                  *ngIf="link.type.name == 'linkedin'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-linkedin-in"
                ></i>
                <i
                  *ngIf="link.type.name == 'vimeo'"
                  [ngStyle]="{ color: websiteMainColor }"
                  class="fab fa-vimeo-v"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-xs-12 text-center">
        <a class="navbar-brand">
          <img class="logo" [src]="website_logo" />
          <img
            *ngIf="website_logo == '' || website_logo == null"
            class="logo"
            src="assets/images/Appoxlogo.PNG"
          />
        </a>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <div class="search-container" *ngIf="APP_ID != null">
          <form [formGroup]="searchForm" (ngSubmit)="search()">
            <input
              type="text"
              (keyup.enter)="search()"
              placeholder="{{ 'search' | translate }}"
              [(ngModel)]="searchQuery"
              formControlName="search"
            />
            <button
              type="submit"
              [ngStyle]="{
                'background-color': Header_Bg_Color,
                'border-color': Header_Bg_Color,
                'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight
              }"
            >
              <i class="fa fa-search" style="color: white"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </nav>
  <nav
    class="navbar navbar-expand-lg navbar-light"
    [ngStyle]="{ 'background-color': Header_Bg_Color }"
  >
    <div class="row full-width">
      <div class="col-md-2 col-sm-12 col-xs-12 text-center">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="col-md-8 col-sm-12 col-xs-12 text-center">
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
              <li
                class="nav-item"
                *ngIf="item.menu_design.header == 1"
                [ngStyle]="{ 'margin-inline': '0.5rem' }"
              >
                <a
                  (mouseover)="hoverHeaderItem = i"
                  (mouseleave)="hoverHeaderItem = -1"
                  [ngStyle]="{
                    color: Header_Color,
                    'font-size' : Header_font_size ,
                    'text-transform': Text_Style,
                    'font-family':text_type,
                   'font-weight': text_weight,
                    'border-bottom':
                      hoverHeaderItem == i ||
                      itemSelectedFromHeader == item.name
                        ? '2px solid Header_Color'
                        : 'none'
                  }"
                  class="nav-link"
                  (click)="navigatetoModule(item)"
                  >{{ item.name }}</a
                >
              </li>
            </ng-container>

            <!-- <li class="nav-item" *ngIf="APP_ID != null  ">
                            <a  class="nav-link" (click)="Logout()" data-toggle="collapse" data-target=".navbar-collapse.show" style="cursor: pointer;"  routerLinkActive="active-link">
                                 {{'HEADER.LOGOUT'|translate}}
                            </a>
                        </li> -->
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12">
        <div class="dropdown">
          <button
            [ngStyle]="{
              'background-color': Header_Bg_Color,
              'border-color': Header_Bg_Color,
              'color': Header_Color,
              'text-transform': Text_Style,
              'font-family':text_type,
              'font-weight': text_weight

            }"
            class="btn btn-secondary dropdown-toggle lang-style"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ "language" | translate }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              class="dropdown-item"
              *ngIf="lang.key == 'en'"
              (click)="changeLang('ar')"
            >
              <img class="flag" src="assets/images/ar.png" />
              عربى
            </a>
            <a
              class="dropdown-item"
              *ngIf="lang.key == 'ar'"
              (click)="changeLang('en')"
            >
              <img class="flag" src="assets/images/en.jpg" />
              English
            </a>
          </div>
        </div>

        <!-- <div class="collapse navbar-collapse lang-position" id="navbarNavDropdown" >
                    <ul class="navbar-nav">
                        <li class="nav-item" style="margin-top: -7px;" *ngIf="lang =='en_container'" (click)="changeLanguage('ar_container')">
                            <a [ngStyle]="{'color':websiteMainColor}" class="nav-link d-flex lang" style="font-size: 17px;">
                              <i [ngStyle]="{'color':websiteMainColor}" class="fa fa-globe lang-icon"></i>
                             عربى
                            </a>
                        </li>

                        <li class="nav-item" style="margin-top: -7px;" *ngIf="lang =='ar_container'" (click)="changeLanguage('en_container')">
                            <a [ngStyle]="{'color':websiteMainColor}" class="nav-link d-flex lang" style="font-size: 17px;">
                              <i [ngStyle]="{'color':websiteMainColor}" class="fa fa-globe lang-icon"></i>
                             English
                            </a>
                        </li>
                    </ul>
                
                </div> -->
      </div>
    </div>
  </nav>
</header>
