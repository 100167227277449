<div class="header container-fluid"
  [ngStyle]="{'background-color':Header_Bg_Color,'border-color': Header_Bg_Color}">
  <div class="row justify-content-end">
    <div class="col-md-5 col-sm-12 col-xs-12 nav">
      <!-- change lang button -->
      <a class="btn nav-link" *ngIf="lang.key == 'en'" (click)="changeLang('ar')">
        AR
        <i class="fa fa-thin fa-globe"></i>
      </a>
      <a class="btn nav-link" *ngIf="lang.key == 'ar'" (click)="changeLang('en')">
        EN
        <i class="fa fa-thin fa-globe"></i>
      </a>
      <!-- login button -->
      <a class="btn nav-link" href="#">{{'Login'| translate}}</a>
      <!-- register button -->
      <a class="btn register-btn nav-link" href="#"
        [ngStyle]="{'background-color':Header_Bg_Color,'border-color': Header_Bg_Color , 'text-transform': Text_Style ,
        'font-family':text_type,
        'font-weight': text_weight}">{{'Register'|translate}}</a>

    </div>
  </div>
</div>
