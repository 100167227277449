import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-about-tabs-image",
  templateUrl: "./about-tabs-image.component.html",
  styleUrls: ["./about-tabs-image.component.scss"],
})
export class AboutTabsImageComponent implements OnInit {
  @Input() article: any;

  ImageTitleData;

  lang: string;
  currentLang: string;

  image_position: any;
  outData: any;
  Button_Color: any;
  Button_Background_Color: any;
  websiteSecColor: any;
  type: string = "mission";

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.image_position = this.article.image_position;
    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color;
    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.ImageTitleData = res.content;

    });

    this.outData = this.ImageTitleData;
     
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
         
      });
    });
  }
  chooseType(type: string) {
    this.type = type;
  }

  navigateToShow(id) {
    this.router.navigateByUrl(`cms/2/${id}`);
   
  }

}
