import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {BackgroundpopupComponent} from '../backgroundpopup/backgroundpopup.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-advertising",
  templateUrl: "./advertising.component.html",
  styleUrls: ["./advertising.component.scss"],
})
export class AdvertisingComponent implements OnInit {
  @Input() article: any;
  APIParameters;
  Adertising_Data: any[] = [];

  websiteMainColor: any;
  websiteSecColor: any;

  ComponentTitle: any;
  lang: string;
  currentLang: string;

  Items_Number;

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;

  hide_title;
  hide_description;

  component_title_position;
  html_content: any;

  Button_Color;
  Button_Background_Color;
  main_id: any;

  APP_ID;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    private modalService: NgbModal,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {
    this.APP_ID = localStorage.getItem("Application_ID");
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Items_Number = this.article.items_number;
    this.main_id = this.article.main_id;

    this.title_font_color = this.article.title_font_color;
    this.title_font_size = this.article.title_font_size;

    this.desc_font_color = this.article.desc_font_color;
    this.desc_font_size = this.article.desc_font_size;

    this.component_sub_title = this.article.component_description;

    this.hide_description = this.article.show_cms_description;
    this.hide_title = this.article.show_cms_title;

    this.component_title_position = this.article.component_title_position;

    this.html_content = this.article.text_as_html;

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color
   
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Adertising_Data = res.content;
        this.ComponentTitle = this.article.component_title;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
        
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
          this.ComponentTitle = this.article.component_title;
        });
    });
  }
  
    navigateToShow(id) {
      this.navigationService.navigateToShow(this.article,id,2);
    }

  NavigateToVoluteer() {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cmsforms`]);
    });
  }

  OpenPopup(data){
    const modalRef = this.modalService.open(BackgroundpopupComponent, { });
    modalRef.componentInstance.fromParent = data;
  }

}
