<!-- <app-academy-register *ngIf="viewRegister" [overlay]="true" (closePopup)="register(false)"></app-academy-register> -->
<app-academy-register-no-bg
  *ngIf="viewRegister"
  [overlay]="true"
  (closePopup)="register(false)"
></app-academy-register-no-bg>
<header>
  <div
    class="flex"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <div class="btn-option">
      <button class="btn login">Login</button>
      <button class="btn singUp" (click)="register(true)">Sign up</button>
    </div>
    <div class="lang">
      <span><i class="fa fa-globe" aria-hidden="true"></i> AR </span>
    </div>
  </div>
</header>

<div
  class="menu-container"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
>
  <div class="search">
    <div class="search-icon"></div>
    <input class="search-input" placeholder="Search" />
  </div>

  <div class="menu-items">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand">
        <img class="logo" [src]="website_logo" width="100" height="100" />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <ng-container
            *ngFor="let item of MenuItems | slice : 0 : 3; let i = index"
          >
            <li class="nav-item" *ngIf="item.menu_design.header == 1">
              <a
                class="nav-link"
                [routerLinkActive]="'active-link'"
                (click)="navigatetoModule(item)"
                (mouseover)="hoverHeaderItem = i"
                (mouseleave)="hoverHeaderItem = -1"
                [ngStyle]="{
                  color: websiteMainColor,
                  'white-space': 'nowrap',
                  'border-bottom':
                    hoverHeaderItem == i || itemSelectedFromHeader == item.name
                      ? '2px solid' + websiteMainColor
                      : 'none'
                }"
              >
                {{ item.name }}
                <!-- <span class="sr-only">(current)</span> -->
              </a>
            </li>
          </ng-container>

          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li> -->
        </ul>
      </div>

      <div class="form-inline my-2 my-lg-0" (click)="openMenu()">
        <i class="fa fa-bars"></i>
      </div>
    </nav>
  </div>
</div>

<div
  id="mySidebar"
  class="sidebar"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [ngStyle]="{ display: overlay == true ? 'block' : 'none' }"
>
  <a
    *ngFor="let item of MenuItems | slice : 3 : 20; let i = index"
    (mouseover)="hoverHeaderItem = i + 3"
    (mouseleave)="hoverHeaderItem = -1"
    [ngStyle]="{
      color: websiteMainColor,
      'white-space': 'nowrap',
      'border-bottom':
        hoverHeaderItem == i + 3 || itemSelectedFromHeader == item.name
          ? '2px solid' + websiteMainColor
          : 'none'
    }"
    [routerLinkActive]="'active-link'"
    (click)="navigatetoModule(item)"
  >
    {{ item.name }}
  </a>
</div>

<div
  class="overlay"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  (click)="closeMenu()"
  [ngStyle]="{ display: overlay == true ? 'block' : 'none' }"
></div>
