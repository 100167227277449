<div [appMyStyles]="article">
 
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <!-- <div
    class="d-flex row justify-content-center"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="align-items-start col-5 m-3 d-flex flex-wrap">
      <div class="row col-12 my-2">
        <button class="btn py-2 rounded-0 col">Our mission</button>
        <button class="btn py-2 rounded-0 col">Our vision</button>
        <button class="btn py-2 rounded-0 col">Our values</button>
      </div>
      <p
        class="col-12 my-2"
        [innerHtml]="ImageTitleData[0]?.description_detailed"
      ></p>
    </div>
    <div class="col-5 m-3">
      <img src="{{ ImageTitleData[0].image }}" />
    </div>
  </div> -->


  <div class="overview__header mar"   [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
    >
   

    <h1
      (click)="chooseType('mission')"
     
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'mission' ? websiteSecColor : '#000',
        'border-bottom': type == 'mission'  ? '3px solid' + websiteSecColor : 'none'

      }"
    >
      {{ ImageTitleData[0].title }}
    </h1>

   

    <h1
      (click)="chooseType('vision')"
     
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'vision' ? websiteSecColor : '#000',
        'border-bottom': type == 'vision'  ? '3px solid' + websiteSecColor : 'none'

      }"
    >
      {{ ImageTitleData[1].title }}
    </h1>

   
    <!-- <h1
      (click)="chooseType('values')"
      
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'values' ? '#A29E9E' : '#A29E9E',
        'border-bottom': type == 'values'  ? '3px solid' + websiteSecColor : 'none'

      }"
      *ngIf="hide_title == 1 && html_content == 0"
    >
      {{ ImageTitleData[2].title }}
    </h1> -->
  </div>

  <div
    class="overview__body"
    *ngIf="type == 'mission'"
    (click)="navigateToShow(ImageTitleData[0].id)"
  >
   
    <h1
      class="overview__body--title mar"
    >
      {{ ImageTitleData[0].description }}
    </h1>
    <!-- <p
      class="overview__body--desc"
      [innerHTML]="About_us_overview_Data[0].content"
    ></p> -->
    <img class="overview__body--desc" src="{{ImageTitleData[0].image}}">
  </div>

  <div
    class="overview__body"
    *ngIf="type == 'vision'"
    (click)="navigateToShow(ImageTitleData[1].id)"
  >
  
    <h1
      class="overview__body--title mar"
    >
      {{ ImageTitleData[1].description }}
    </h1>
    <!-- <p
      class="overview__body--desc"
      [innerHTML]="ImageTitleData[1].content"
    ></p> -->
    <img class="overview__body--desc" src="{{ImageTitleData[1].image}}">

  </div>

  <!-- <div
    class="overview__body mar"
    *ngIf="type == 'values'"
    (click)="navigateToShow(ImageTitleData[2].id)"
  >
   
    <h1
      class="overview__body--title"
      *ngIf="hide_description == 1 && html_content == 0"
    >
      {{ ImageTitleData[2].description }}
    </h1>
    
    <img class="overview__body--desc" src="{{ImageTitleData[2].image}}">

  </div> -->


</div>
