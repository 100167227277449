import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";

@Component({
  selector: "app-image-title",
  templateUrl: "./image-title.component.html",
  styleUrls: ["./image-title.component.scss"],
})
export class ImageTitleComponent implements OnInit {
  @Input() article: any;

  lang: string;
  currentLang: string;

  image_position: any;
  outData: any;
  ImageTitleData: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.image_position = this.article.image_position;

    this.homeService
    .getHomeComponentsData(this.article)
    .subscribe((res: any) => {
      this.ImageTitleData = res.content;
    });

    this.outData = this.ImageTitleData;

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
      });
    });
  }
}
