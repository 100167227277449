<div class="row" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [appMyStyles]="article"
  *ngIf="article.main_id != 16315"
>
  <div
    class="col-md-3 col-sm-6 col-xs-12 mar-bottom-col"
    *ngFor="let item of FourbuttoncardsData | slice : 0 : article.items_number"
  >
    <div
      class="card hover-style"
      *ngIf="main_id == 15768"
      [ngStyle]="{ 'background-color': websiteMainColor }"
    >
      <img class="card-img" src="{{ item.image }}" />
      <!-- <iframe *ngFor="let video of Videos" width="100%" height="150px;" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

      <!-- <ng-container *ngIf="item.media > 0">
                    <div *ngFor="let video of item.media" style="margin-bottom: 15px;height: 150px;">
                        <iframe *ngIf="video.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-container> -->

     

      <h6
        style="color: white !important"
        class="card-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ item.title }}
      </h6>
      <p
        class="card-paragraph"
        style="color: white !important"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ item.description }}
      </p>

      <button
        (click)="navigateToShow(item.id)"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color,
          'border-color': Button_Background_Color
        }"
        class="read-more"
      >
        {{ "Book Now" | translate }}
      </button>
    </div>

    <div class="card hover-style" *ngIf="main_id != 15768">
      <img class="card-img" src="{{ item.image }}" />
   

      <h6  [ngStyle]="{
                  'font-size': article.title_size,
                   color: article.title_color
      }">
        {{ item.title }}
      </h6>

      <p [ngStyle]="{
        'font-size': article.description_size,
        color: article.description_color
        }">
        {{ item.description }}
      </p>

      <!-- <button
        (click)="navigateToShow(item.id)"
        [ngStyle]="{
          'background-color': websiteMainColor,
          'border-color': websiteMainColor
        }"
        class="read-more"
      >
        {{ "Read More" | translate }}
      </button> -->
    </div>
  </div>
</div>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  [appMyStyles]="article"
  *ngIf="article.main_id == 16315"
>
  <div
    class="col-md-4 col-sm-6 col-xs-12 mar-bottom-col"
    *ngFor="let item of FourbuttoncardsData | slice : 0 : article.items_number"
  >
    <div
      class="card hover-style"
      *ngIf="main_id == 15768"
      [ngStyle]="{ 'background-color': websiteMainColor }"
    >
      <img class="card-img" src="{{ item.image }}" />
      <!-- <iframe *ngFor="let video of Videos" width="100%" height="150px;" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

      <!-- <ng-container *ngIf="item.media > 0">
                  <div *ngFor="let video of item.media" style="margin-bottom: 15px;height: 150px;">
                      <iframe *ngIf="video.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
              </ng-container> -->

      <h6
        class="card-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
        style="color: white !important"
      ></h6>
      <p
        class="card-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="item.description_detailed"
        style="color: white !important"
      ></p>

      <h6
        style="color: white !important"
        class="card-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
      >
        {{ item.title }}
      </h6>
      <p
        class="card-paragraph"
        style="color: white !important"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ item.description }}
      </p>

      <button
        (click)="navigateToShow(item.id)"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color,
          'border-color': Button_Background_Color
        }"
        class="read-more"
      >
        {{ "Read More" | translate }}
      </button>
    </div>

    <div class="card hover-style" *ngIf="main_id != 15768">
      <img class="card-img" src="{{ item.image }}" />
      <!-- <iframe *ngFor="let video of Videos" width="100%" height="150px;" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

      <!-- <ng-container *ngIf="item.media > 0">
                  <div *ngFor="let video of item.media" style="margin-bottom: 15px;height: 150px;">
                      <iframe *ngIf="video.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(video.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
              </ng-container> -->

      <h6
        class="card-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></h6>
      <p
        class="card-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="item.description_detailed"
      ></p>

      <h6 class="card-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ item.title }}
      </h6>
      <p
        class="card-paragraph"
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
      >
        {{ item.description }}
      </p>

      <button
        (click)="OpenPopup(item)"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color,
          'border-color': Button_Color
        }"
        class="donate"
      >
        {{ "Donate" | translate }}
      </button>
    </div>

  </div>
</div>
