<div class="container-fluid" style="padding: 0px">
  <!--================ Start Up Positions  ================  -->

  <div
    class="row"
    *ngIf="
      module_components_up_sorted.length > 0 &&
      module_components_up_right_sorted.length == 0 &&
      module_components_up_left_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 0px">
      <div *ngFor="let item of module_components_up_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length > 0 &&
      module_components_up_right_sorted.length > 0 &&
      module_components_up_left_sorted.length == 0
    "
  >
    <div class="col-md-8 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length > 0 &&
      module_components_up_right_sorted.length == 0 &&
      module_components_up_left_sorted.length > 0
    "
  >
    <div class="col-md-4 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length > 0 &&
      module_components_up_right_sorted.length > 0 &&
      module_components_up_left_sorted.length > 0
    "
  >
    <div class="col-md-3 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-3 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length == 0 &&
      module_components_up_right_sorted.length > 0 &&
      module_components_up_left_sorted.length > 0
    "
  >
    <div class="col-md-6 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length == 0 &&
      module_components_up_right_sorted.length == 0 &&
      module_components_up_left_sorted.length > 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length == 0 &&
      module_components_up_right_sorted.length > 0 &&
      module_components_up_left_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <!--================ End Up Positions  ================  -->

  <div
    class="row"
   
    *ngIf="
      module_components_left_sorted.length > 0 &&
      module_components_right_sorted.length > 0 &&
      module_components_middle_sorted.length == 0
    "
  >
    <div class="col-md-6 col-sm-12 col-xs-12">
      <div style="margin-bottom: 5px">
        <div *ngFor="let item of module_components_right_sorted">
          <ng-container
            *ngTemplateOutlet="components; context: { $implicit: item }"
          ></ng-container>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div style="margin-bottom: 5px">
        <div *ngFor="let item of module_components_left_sorted">
          <ng-container
            *ngTemplateOutlet="components; context: { $implicit: item }"
          ></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length > 0 &&
      module_components_right_sorted.length > 0 &&
      module_components_middle_sorted.length > 0
    "
  >
    <div class="col-md-3 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_left_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_middle_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-3 col-sm-12 col-xs-12">
      <div
        *ngFor="let item of module_components_right_sorted"
        style="margin-bottom: 5px"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length == 0 &&
      module_components_right_sorted.length == 0 &&
      module_components_middle_sorted.length > 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_middle_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length == 0 &&
      module_components_right_sorted.length > 0 &&
      module_components_middle_sorted.length > 0
    "
  >
    <div class="col-md-8 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_middle_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_right_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length == 0 &&
      module_components_right_sorted.length > 0 &&
      module_components_middle_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_right_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length > 0 &&
      module_components_right_sorted.length == 0 &&
      module_components_middle_sorted.length > 0
    "
  >
    <div class="col-md-4 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_left_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_middle_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length > 0 &&
      module_components_right_sorted.length == 0 &&
      module_components_middle_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div
        style="margin-bottom: 5px"
        *ngFor="let item of module_components_left_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <!--================ Start Down Positions  ================  -->

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length > 0 &&
      module_components_down_right_sorted.length == 0 &&
      module_components_down_left_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length > 0 &&
      module_components_down_right_sorted.length > 0 &&
      module_components_down_left_sorted.length == 0
    "
  >
    <div class="col-md-8 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length > 0 &&
      module_components_down_right_sorted.length == 0 &&
      module_components_down_left_sorted.length > 0
    "
  >
    <div class="col-md-4 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length > 0 &&
      module_components_down_right_sorted.length > 0 &&
      module_components_down_left_sorted.length > 0
    "
  >
    <div class="col-md-3 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-3 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length == 0 &&
      module_components_down_right_sorted.length > 0 &&
      module_components_down_left_sorted.length > 0
    "
  >
    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length == 0 &&
      module_components_down_right_sorted.length > 0 &&
      module_components_down_left_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 5px">
      <div *ngFor="let item of module_components_down_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length == 0 &&
      module_components_down_right_sorted.length == 0 &&
      module_components_down_left_sorted.length > 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_down_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <!--================ End Down Positions  ================  -->
</div>

<ng-template let-item #components>
  <div class="edit-component" (click)="showDialog('component',item)">
    <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-rounded" style="margin: 5px;"></button>
  </div>
  <app-slider
    [article]="item"
    *ngIf="(item.id == 65)&& item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="65"
  ></app-slider>
  <!--no title -->

  <app-two-image-big-cards
    [article]="item"
    *ngIf="item.id == 93 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
  ></app-two-image-big-cards>
  <!-- title desc dynamic added -->

  <app-cms-most-watched-component
    [article]="item"
    *ngIf="item.id == 80 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="80"
  ></app-cms-most-watched-component>
  <!--  no title -->
  
  <!-- <app-cms-tags-component [article]="item" *ngIf="item.id == 81 && item.hidden == 0 && item.block == 1  " data-aos-duration="2000" ></app-cms-tags-component> -->

  <app-card3-white-bg
    [component]="item"
    *ngIf="item.id == 82 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="82"
  ></app-card3-white-bg>
  <!-- title desc dynamic added -->

  <app-image-title-subtitle-description-button-card
    [article]="item"
    *ngIf="item.id == 72 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="72"
  ></app-image-title-subtitle-description-button-card>
  <!-- title desc dynamic added -->

  <app-cards
    [article]="item"
    *ngIf="item.id == 71 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="71"
  ></app-cards>
  <!-- title desc dynamic added -->

  <app-title-description-cards2
    [article]="item"
    *ngIf="item.id == 69 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="69"
  ></app-title-description-cards2>
  <!-- title desc dynamic added -->

  <app-title-description-card
    [article]="item"
    *ngIf="item.id == 68 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="68"
  ></app-title-description-card>
   <!-- title desc dynamic added -->
  <app-slider-title-description-card
    [article]="item"
    *ngIf="item.id == 66 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="66"
  ></app-slider-title-description-card>
   <!-- title desc dynamic added -->

  <app-img-title-card
    [article]="item"
    *ngIf="item.id == 63 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="63"
  ></app-img-title-card>
   <!-- title desc dynamic added -->

  <app-date-cards3
    [article]="item"
    *ngIf="item.id == 62 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="62"
  >
  </app-date-cards3>
   <!-- title desc dynamic added -->

  <app-circles4
    [component]="item"
    *ngIf="item.id == 61 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="61"
  ></app-circles4>
   <!-- title desc dynamic added -->

  <app-circles3
    [article]="item"
    *ngIf="item.id == 60 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="60"
  ></app-circles3>
   <!-- title desc dynamic added -->

  <app-cards4
    [component]="item"
    *ngIf="item.id == 59 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="59"
  ></app-cards4>
   <!-- title desc dynamic added -->

  <app-cards3
    [article]="item"
    *ngIf="item.id == 58 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="58"
  ></app-cards3>
   <!-- title desc dynamic added -->

  <app-button-cards4
    [article]="item"
    *ngIf="item.id == 57 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="57"
  ></app-button-cards4>
   <!-- title desc dynamic added -->

  <app-button-cards2
    [article]="item"
    *ngIf="item.id == 56 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="56"
  ></app-button-cards2>
   <!-- title desc dynamic added -->

  <app-img-title-description-card
    [component]="item"
    *ngIf="item.id == 64 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="64"
  ></app-img-title-description-card>
   <!-- title desc dynamic added -->

  <app-image-slider
    [article]="item"
    *ngIf="item.id == 94 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="94"
  ></app-image-slider>
   <!-- title desc dynamic added -->

  <app-icon-title-desc-card3
    [article]="item"
    *ngIf="item.id == 95 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="95"
  ></app-icon-title-desc-card3>
  <!-- title desc dynamic added -->

  <app-icon-bg-title-desc-card3
    [article]="item"
    *ngIf="item.id == 96 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="96"
  ></app-icon-bg-title-desc-card3>
  <!-- title desc dynamic added -->

  <app-cards6-slider
    [article]="item"
    *ngIf="item.id == 129 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="129"
  ></app-cards6-slider>
  <!-- title desc dynamic added -->

  <app-side-new
    [article]="item"
    *ngIf="
      item.id == 78 || (item.id == 84 && item.hidden == 0 && item.block == 1)
    "
    data-aos-duration="2000"
    id="78"
  ></app-side-new>
  <!-- title desc dynamic added -->

  <app-big-image-side
    [article]="item"
    *ngIf="item.id == 79 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="79"
  ></app-big-image-side>
  <!-- title desc dynamic added -->

  <app-web-slider
    [article]="item"
    *ngIf="item.id == 106 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="106"
  ></app-web-slider>
  <!-- title desc dynamic added -->

  <app-slider3items
    [article]="item"
    *ngIf="item.id == 114 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="114"
  >
  </app-slider3items>
  <!-- title desc dynamic added -->

  <app-logo-slider
    [article]="item"
    *ngIf="item.id == 116 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="116"
  ></app-logo-slider>
  <!-- title desc dynamic added -->

  <app-cards6
    [article]="item"
    *ngIf="item.id == 117 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="117"
  ></app-cards6>
  <!-- title desc dynamic added -->

  <app-gallery3
    [article]="item"
    *ngIf="item.id == 120 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="120"
  ></app-gallery3>
  <!-- title desc dynamic added -->

  <app-getintouch
    [article]="item"
    *ngIf="item.id == 125 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="125"
  ></app-getintouch>
  <!-- title desc dynamic added -->

  <app-events-cards3
    [article]="item"
    *ngIf="item.id == 126 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="126"
  >
  </app-events-cards3>
  <!-- title desc dynamic added -->

  <app-cards-list
    [component]="item"
    *ngIf="item.id == 127 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="127"
  ></app-cards-list>

  <app-grid-cards
    [article]="item"
    *ngIf="item.id == 128 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="128"
  ></app-grid-cards>
  <!-- title desc dynamic added -->

  <app-cards-title-list
    [article]="item"
    *ngIf="item.id == 130 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="130"
  >
  </app-cards-title-list>
  <!-- title desc dynamic added -->

  <app-gallery-list
    [article]="item"
    *ngIf="item.id == 131 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="131"
  >
  </app-gallery-list>
  <!-- title desc dynamic added -->

  <app-image-title-desc-horizontzal-cards
    [article]="item"
    *ngIf="item.id == 133 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="133"
  >
  </app-image-title-desc-horizontzal-cards>
  <!-- title desc dynamic added -->

  <app-image-title-description
    [component]="item"
    *ngIf="item.id == 107 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="107"
  ></app-image-title-description>
  <app-cards-slider
    [article]="item"
    *ngIf="item.id == 108 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="108"
  ></app-cards-slider>
  <!-- title desc dynamic added -->

  <app-big-video
    [article]="item"
    *ngIf="item.id == 109 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="109"
  ></app-big-video>
  <!-- title desc dynamic added -->

  <app-objectives-cards
    [article]="item"
    *ngIf="item.id == 110 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="110"
  ></app-objectives-cards>
  <!-- title desc dynamic added -->

  <app-objective-card1
    [article]="item"
    *ngIf="item.id == 142 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="142"
  ></app-objective-card1>
  <!-- title desc dynamic added -->

  <app-media
    [article]="item"
    *ngIf="item.id == 112 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="112"
  ></app-media>
  <!-- title desc dynamic added -->

  <app-advertising
    [article]="item"
    *ngIf="item.id == 111 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="111"
  ></app-advertising>

  <app-about-us-tornado
    [article]="item"
    *ngIf="item.id == 138 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="138"
  ></app-about-us-tornado>
  <!-- title desc dynamic added -->

  <app-academy-menu
    [article]="item"
    *ngIf="item.id == 137 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="137"
  ></app-academy-menu>
  <app-academy-register
    [article]="item"
    *ngIf="item.id == 139 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="139"
  ></app-academy-register>
  <app-academy-register-no-bg
    [article]="item"
    *ngIf="item.id == 140 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="140"
  ></app-academy-register-no-bg>
  <app-popup-option
    [article]="item"
    *ngIf="item.id == 141 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="141"
  ></app-popup-option>

  <app-slider-title-desc-sign
    [article]="item"
    *ngIf="item.id == 97 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="97"
  >
  </app-slider-title-desc-sign>
  <!-- title desc dynamic added -->

  <app-slider-image-sign-content
    [component]="item"
    *ngIf="item.id == 98 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="98"
  >
  </app-slider-image-sign-content>
  <!-- title desc dynamic added -->

  <app-slider-sign-qoute-image
    [article]="item"
    *ngIf="item.id == 99 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="99"
  >
  </app-slider-sign-qoute-image>
  <!-- title desc dynamic added -->

  <app-slider-img-content-title
    [article]="item"
    *ngIf="item.id == 100 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="100"
  >
  </app-slider-img-content-title>
  <!-- title desc dynamic added -->

  <app-slider-img-animate-title
    [article]="item"
    *ngIf="item.id == 101 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="101"
  >
  </app-slider-img-animate-title>
  <!-- title desc dynamic added -->

  <app-slider-fixed-img-title
    [component]="item"
    *ngIf="item.id == 102 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="102"
  >
  </app-slider-fixed-img-title>
  <!-- title desc dynamic added -->

  <app-slider-hover-buttons
    [article]="item"
    *ngIf="
      item.id == 103 || (item.id == 104 && item.hidden == 0 && item.block == 1)
    "
    data-aos-duration="2000"
    id="103"
  >
  </app-slider-hover-buttons>
  <!-- title desc dynamic added -->

  <app-grid-cards5
    [article]="item"
    *ngIf="item.id == 150 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="150"
  >
  </app-grid-cards5>
  <!-- title desc dynamic added -->

  <app-statistics-cards
    [article]="item"
    *ngIf="item.id == 67 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="67"
  >
  </app-statistics-cards>
  <!-- title desc dynamic added -->

  <app-video
    [article]="item"
    *ngIf="item.id == 165 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="165"
  >
  </app-video><!--------------- EMPTY COMPONENT --------------->

  <app-timeline
    [article]="item"
    *ngIf="item.id == 161 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="161"
  >
  </app-timeline><!--------------- EMPTY COMPONENT --------------->

  <app-pdf
    [article]="item"
    *ngIf="item.id == 163 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="163"
  >
  </app-pdf><!--------------- EMPTY COMPONENT --------------->

  <app-line-chart
    [article]="item"
    *ngIf="item.id == 162 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="162"
  >
  </app-line-chart><!--------------- EMPTY COMPONENT --------------->

  <app-data-table
    [article]="item"
    *ngIf="item.id == 164 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="164"
  >
  </app-data-table> <!--------------- EMPTY COMPONENT --------------->

  <app-contact-info-card
    [article]="item"
    *ngIf="item.id == 177 && item.hidden == 0 && item.block == 1"
    id="177"
  >
  </app-contact-info-card>
  <!-- title desc dynamic added -->

  <app-google-map
    [article]="item"
    *ngIf="item.id == 175 && item.hidden == 0 && item.block == 1"
    id="175"
  >
  </app-google-map>

  <app-reservation-form1
    [article]="item"
    *ngIf="item.id == 176 && item.hidden == 0 && item.block == 1"
    id="176"
  >
  </app-reservation-form1>
  <!-- title desc dynamic added -->

  <app-advertising2
    [article]="item"
    *ngIf="item.id == 182 && item.hidden == 0 && item.block == 1"
    id="182"
  ></app-advertising2>

  <app-reservation-form2
    [article]="item"
    *ngIf="item.id == 183 && item.hidden == 0 && item.block == 1"
    id="183"
  >
  </app-reservation-form2>
  <!-- title desc dynamic added -->

  <app-reservation-form3
    [article]="item"
    *ngIf="item.id == 181 && item.hidden == 0 && item.block == 1"
    id="181"
  >
  </app-reservation-form3>

  <app-card3-vertical
    [article]="item"
    *ngIf="item.id == 70 && item.hidden == 0 && item.block == 1"
    id="70"
  >
  </app-card3-vertical>
  <!-- title desc dynamic added -->

  <app-title-side-description-card
    [article]="item"
    *ngIf="item.id == 147 && item.hidden == 0 && item.block == 1"
    id="147"
  >
  </app-title-side-description-card>
  <!-- title desc dynamic added -->

  <app-cms-component1-general
    [component]="item"
    *ngIf="item.id == 86 && item.hidden == 0 && item.block == 1"
    id="86"
  ></app-cms-component1-general>
  <!-- title desc dynamic added -->

  <app-cms-component2-general
    [article]="item"
    *ngIf="item.id == 87 && item.hidden == 0 && item.block == 1"
    id="87"
  ></app-cms-component2-general>
  <!-- title desc dynamic added -->

  <app-cms-component3-general
    [article]="item"
    *ngIf="item.id == 88 && item.hidden == 0 && item.block == 1"
    id="88"
  ></app-cms-component3-general>
  <!-- title desc dynamic added -->

  <app-cms-component4-general
    [article]="item"
    *ngIf="item.id == 89 && item.hidden == 0 && item.block == 1"
    id="89"
  ></app-cms-component4-general>
  <!-- title desc dynamic added -->

  <app-cms-component6-general
    [article]="item"
    *ngIf="item.id == 90 && item.hidden == 0 && item.block == 1"
    id="90"
  ></app-cms-component6-general>
  <!-- title desc dynamic added -->

  <app-cms-component7-general
    [component]="item"
    *ngIf="item.id == 91 && item.hidden == 0 && item.block == 1"
    id="91"
  ></app-cms-component7-general>
  <!-- title desc dynamic added -->

  <app-cms-component8-general
    [component]="item"
    *ngIf="item.id == 92 && item.hidden == 0 && item.block == 1"
    id="92"
  ></app-cms-component8-general>
  <!-- title desc dynamic added -->

  <app-image2-title-description
    [article]="item"
    *ngIf="item.id == 184 && item.hidden == 0 && item.block == 1"
    id="184"
  >
  </app-image2-title-description>
  <!-- title desc dynamic added -->

  <app-video-title-description
    [article]="item"
    *ngIf="item.id == 185 && item.hidden == 0 && item.block == 1"
    id="185"
  >
  </app-video-title-description>
  <!-- title desc dynamic added -->

  <app-contactus1
    [article]="item"
    *ngIf="item.id == 186 && item.hidden == 0 && item.block == 1"
    id="186"
  >
  </app-contactus1>
  <!-- title desc dynamic added -->

  <app-openingtime
    [article]="item"
    *ngIf="item.id == 189 && item.hidden == 0 && item.block == 1"
    id="189"
  >
  </app-openingtime>
  <!-- title desc dynamic added -->

  <app-appointmentcard-info
    [article]="item"
    *ngIf="item.id == 190 && item.hidden == 0 && item.block == 1"
    id="190"
  >
  </app-appointmentcard-info>
  <!-- title desc dynamic added -->

  <app-img-title-desc2
    [article]="item"
    *ngIf="item.id == 191 && item.hidden == 0 && item.block == 1"
    id="191"
  >
  </app-img-title-desc2>
  <!-- title desc dynamic added -->

  <app-image-big-title-description
    [article]="item"
    *ngIf="item.id == 188 && item.hidden == 0 && item.block == 1"
    id="188"
  ></app-image-big-title-description>
  <!-- title desc dynamic added -->

  <app-advertising-images3
    [article]="item"
    *ngIf="item.id == 194 && item.hidden == 0 && item.block == 1"
    id="194"
  >
  </app-advertising-images3>
  <!-- title desc dynamic added -->
  
  <app-color-size-form
    [article]="item"
    *ngIf="item.id == 196 && item.hidden == 0 && item.block == 1"
    id="196"
  >
  </app-color-size-form>
  <!-- title desc dynamic added -->

  <app-images-grid
    [article]="item"
    *ngIf="item.id == 197 && item.hidden == 0 && item.block == 1"
    id="197"
  >
  </app-images-grid>
  <!-- title desc dynamic added -->

  <app-img-title-dec-grid
    [article]="item"
    *ngIf="item.id == 198 && item.hidden == 0 && item.block == 1"
    id="198"
  >
  </app-img-title-dec-grid>
  <!-- title desc dynamic added -->

  <app-comments-with-cards
    [component]="item"
    *ngIf="item.id == 212 && item.hidden == 0 && item.block == 1"
    id="212"
  ></app-comments-with-cards>
  <!-- title desc dynamic added -->

  <app-button-into-image
    [component]="item"
    *ngIf="item.id == 213 && item.hidden == 0 && item.block == 1"
    id="213"
  ></app-button-into-image>
  <!-- title desc dynamic added -->

  <app-cards7
    [article]="item"
    *ngIf="item.id == 214 && item.hidden == 0 && item.block == 1"
    id="214"
  ></app-cards7>
  <!-- title desc dynamic added -->

  <app-image-with-benfits
    [component]="item"
    *ngIf="item.id == 224 && item.hidden == 0 && item.block == 1"
    id="224"
  ></app-image-with-benfits>
  <!-- title desc dynamic added -->

  <app-image-with-two-list
    [article]="item"
    *ngIf="item.id == 228 && item.hidden == 0 && item.block == 1"
    id="228"
  ></app-image-with-two-list>
  <!-- title desc dynamic added -->

  <app-list-of-images
    [article]="item"
    *ngIf="item.id == 210 && item.hidden == 0 && item.block == 1"
    id="210"
  ></app-list-of-images>
  <!-- title desc dynamic added -->

  <app-list-with-description
    [article]="item"
    *ngIf="item.id == 209 && item.hidden == 0 && item.block == 1"
    id="209"
  ></app-list-with-description>
  <!-- title desc dynamic added -->

  <app-menue-buttons
    [article]="item"
    *ngIf="item.id == 208 && item.hidden == 0 && item.block == 1"
    id="208"
  ></app-menue-buttons>
  <!-- title desc dynamic added -->

  <app-products-with-cards
    [article]="item"
    *ngIf="item.id == 207 && item.hidden == 0 && item.block == 1"
    id="207"
  ></app-products-with-cards>
  <!-- title desc dynamic added -->

  <app-images-with-four-tabs
    [article]="item"
    *ngIf="item.id == 239 && item.hidden == 0 && item.block == 1"
    id="239"
  ></app-images-with-four-tabs>
  <!-- title desc dynamic added -->

  <app-big-background-title-description
    [article]="item"
    *ngIf="item.id == 221 && item.hidden == 0 && item.block == 1"
    id="221"
  >
  </app-big-background-title-description>
  <!-- title desc dynamic added -->

  <app-title-description-background
    [article]="item"
    *ngIf="item.id == 193 && item.hidden == 0 && item.block == 1"
    id="193"
  >
  </app-title-description-background>
  <!-- title desc dynamic added -->

  <app-packages-cards4
    [article]="item"
    *ngIf="item.id == 215 && item.hidden == 0 && item.block == 1"
    id="215"
  >
  </app-packages-cards4>
  <!-- title desc dynamic added -->

  <app-contactus2
    [article]="item"
    *ngIf="item.id == 227 && item.hidden == 0 && item.block == 1"
    id="227"
  >
  </app-contactus2>
  <!-- title desc dynamic added -->

  <app-three-logo
    [article]="item"
    *ngIf="item.id == 226 && item.hidden == 0 && item.block == 1"
    id="226"
  ></app-three-logo>
  <!-- title desc dynamic added -->

  <app-contactus3
    [component]="item"
    *ngIf="item.id == 223 && item.hidden == 0 && item.block == 1"
    id="223"
  ></app-contactus3>
  <!-- title desc dynamic added -->

  <app-contactus4
    [component]="item"
    *ngIf="item.id == 220 && item.hidden == 0 && item.block == 1"
    id="220"
  ></app-contactus4>
  <!-- title desc dynamic added -->

  <app-title-desc-list-two-images
    [article]="item"
    *ngIf="item.id == 218 && item.hidden == 0 && item.block == 1"
    id="218"
  ></app-title-desc-list-two-images>
  <!-- title desc dynamic added -->

  <app-image-title-desc-button
    [article]="item"
    *ngIf="item.id == 219 && item.hidden == 0 && item.block == 1"
    id="219"
  ></app-image-title-desc-button>
  <!-- title desc dynamic added -->

  <app-logo
    [article]="item"
    *ngIf="item.id == 240 && item.hidden == 0 && item.block == 1"
    id="240"
  ></app-logo>
  <!-- title desc dynamic added -->

  <app-description-with-features
    [article]="item"
    *ngIf="item.id == 249 && item.hidden == 0 && item.block == 1"
    id="249"
  ></app-description-with-features>
  <!-- title desc dynamic added -->

  <app-cards-with-image-and-title
    [article]="item"
    *ngIf="item.id == 250 && item.hidden == 0 && item.block == 1"
    id="250"
  ></app-cards-with-image-and-title>
  <!-- title desc dynamic added -->

  <app-background-title-description-list
    [article]="item"
    *ngIf="item.id == 233 && item.hidden == 0 && item.block == 1"
    id="233"
  ></app-background-title-description-list>
  <!-- title desc dynamic added -->

  <app-brands
    [article]="item"
    *ngIf="item.id == 234 && item.hidden == 0 && item.block == 1"
    id="234"
  ></app-brands>
  <!-- title desc dynamic added -->

  <app-image-with-cards
    [article]="item"
    *ngIf="item.id == 238 && item.hidden == 0 && item.block == 1"
    id="238"
  ></app-image-with-cards>
  <!-- title desc dynamic added -->

  <app-images-with-title
    [article]="item"
    *ngIf="item.id == 237 && item.hidden == 0 && item.block == 1"
    id="237"
  ></app-images-with-title>
  <!-- title desc dynamic added -->

  <app-about-item
    [article]="item"
    *ngIf="item.id == 200 && item.hidden == 0 && item.block == 1"
    id="200"
  ></app-about-item>
  <!-- title desc dynamic added -->

  <app-contact-us-with-image
    [article]="item"
    *ngIf="item.id == 235 && item.hidden == 0 && item.block == 1"
    id="235"
  ></app-contact-us-with-image>
  <!-- title desc dynamic added -->

  <app-images-static-position
    [article]="item"
    *ngIf="item.id == 236 && item.hidden == 0 && item.block == 1"
    id="236"
  ></app-images-static-position>
  <!-- title desc dynamic added -->

  <app-image-with-list-followers
    [article]="item"
    *ngIf="item.id == 241 && item.hidden == 0 && item.block == 1"
    id="241"
  ></app-image-with-list-followers>
  <!-- title desc dynamic added -->

  <app-two-image-with-description
    [article]="item"
    *ngIf="item.id == 242 && item.hidden == 0 && item.block == 1"
    id="242"
  ></app-two-image-with-description>
  <!-- title desc dynamic added -->

  <app-flags
    [article]="item"
    *ngIf="item.id == 247 && item.hidden == 0 && item.block == 1"
    id="247"
  ></app-flags>
  <!-- title desc dynamic added -->

  <app-list-image-with-description
    [article]="item"
    *ngIf="item.id == 243 && item.hidden == 0 && item.block == 1"
    id="243"
  ></app-list-image-with-description>
  <!-- title desc dynamic added -->

  <app-random-images
    [article]="item"
    *ngIf="item.id == 244 && item.hidden == 0 && item.block == 1"
    id="244"
  ></app-random-images>
  <!-- title desc dynamic added -->

  <app-blogs
    [component]="item"
    *ngIf="item.id == 245 && item.hidden == 0 && item.block == 1"
    id="245"
  ></app-blogs>
  <!-- title desc dynamic added -->

  <app-description-with-button
    [article]="item"
    *ngIf="item.id == 246 && item.hidden == 0 && item.block == 1"
    id="246"
  ></app-description-with-button>
  <!-- title desc dynamic added -->

  <app-contact-us7
    [article]="item"
    *ngIf="item.id == 248 && item.hidden == 0 && item.block == 1"
    id="248"
  ></app-contact-us7>
  <!-- title desc dynamic added -->

  <app-card-price-description
    [article]="item"
    *ngIf="item.id == 180 && item.hidden == 0 && item.block == 1"
    id="180"
  ></app-card-price-description>
  <!-- title desc dynamic added -->

  <app-brands-title
    [article]="item"
    *ngIf="item.id == 201 && item.hidden == 0 && item.block == 1"
    id="201"
  ></app-brands-title>
  <!-- title desc dynamic added -->

  <app-reservation
    [article]="item"
    *ngIf="item.id == 259 && item.hidden == 0 && item.block == 1"
    id="259"
  ></app-reservation>
  <!-- title desc dynamic added -->

  <app-conversation
    [article]="item"
    *ngIf="item.id == 263 && item.hidden == 0 && item.block == 1"
    id="263"
  ></app-conversation>
  <!-- title desc dynamic added -->

  <app-about-us-overview
    [component]="item"
    *ngIf="item.id == 122 && item.hidden == 0 && item.block == 1"
    id="122"
  ></app-about-us-overview>
  <!-- title desc dynamic added -->

  <app-circle-side3
    [article]="item"
    *ngIf="item.id == 251 && item.hidden == 0 && item.block == 1"
    id="251"
  ></app-circle-side3>
  <!-- title desc dynamic added -->

  <app-images-grid2
    [article]="item"
    *ngIf="item.id == 252 && item.hidden == 0 && item.block == 1"
    id="252"
  ></app-images-grid2>
  <!-- title desc dynamic added -->


  <app-contactus5
    [article]="item"
    *ngIf="item.id == 253 && item.hidden == 0 && item.block == 1"
    id="253"
  ></app-contactus5>
  <!-- title desc dynamic added -->

  <app-date-cards4
    [article]="item"
    *ngIf="item.id == 254 && item.hidden == 0 && item.block == 1"
    id="254"
  ></app-date-cards4>
  <!-- title desc dynamic added -->

  <app-about-us3
    [article]="item"
    *ngIf="item.id == 256 && item.hidden == 0 && item.block == 1"
    id="256"
  ></app-about-us3>
  <!-- title desc dynamic added -->

  <app-faq
    [article]="item"
    *ngIf="item.id == 257 && item.hidden == 0 && item.block == 1"
    id="257"
  ></app-faq>
  <!-- title desc dynamic added -->


  <app-title-desc-search-image
    [article]="item"
    *ngIf="item.id == 255 && item.hidden == 0 && item.block == 1"
    id="255"
  ></app-title-desc-search-image>
  <!-- title desc dynamic added -->

  <app-three-big-images-title
    [article]="item"
    *ngIf="item.id == 262 && item.hidden == 0 && item.block == 1"
    id="262"
  ></app-three-big-images-title>
  <!-- title desc dynamic added -->


  <app-contactus6
    [article]="item"
    *ngIf="item.id == 260 && item.hidden == 0 && item.block == 1"
    id="260"
  ></app-contactus6>
  <!-- title desc dynamic added -->

  <app-reservation-form4
    [article]="item"
    *ngIf="item.id == 261 && item.hidden == 0 && item.block == 1"
    id="261"
  ></app-reservation-form4>
  <!-- title desc dynamic added -->

  <app-slider-desc-search
    [article]="item"
    *ngIf="item.id == 264 && item.hidden == 0 && item.block == 1"
    id="264"
  ></app-slider-desc-search>
  <!-- title desc dynamic added -->

  <app-about-list-image
    [article]="item"
    *ngIf="item.id == 265 && item.hidden == 0 && item.block == 1"
    id="265"
  ></app-about-list-image>
  <app-date-cards3-image
    [article]="item"
    *ngIf="item.id == 274 && item.hidden == 0 && item.block == 1"
    id="274"
  ></app-date-cards3-image>
  <!-- title desc dynamic added -->

  <app-cards-filter
    [article]="item"
    *ngIf="item.id == 266 && item.hidden == 0 && item.block == 1"
    id="266"
  ></app-cards-filter>
  <!-- title desc dynamic added -->
  <app-cards8
    [article]="item"
    *ngIf="item.id == 275 && item.hidden == 0 && item.block == 1"
    id="275"
  ></app-cards8>
  <!-- title desc dynamic added -->
  <app-images-list
    [article]="item"
    *ngIf="item.id == 267 && item.hidden == 0 && item.block == 1"
    id="267"
  ></app-images-list>
  <!-- title desc dynamic added -->
  <app-bg-title-icons
    [article]="item"
    *ngIf="item.id == 270 && item.hidden == 0 && item.block == 1"
    id="270"
  ></app-bg-title-icons>
  <!-- title desc dynamic added -->
  <app-brands-cars
    [article]="item"
    *ngIf="item.id == 273 && item.hidden == 0 && item.block == 1"
    id="273"
  ></app-brands-cars>
  <!-- title desc dynamic added -->
  <app-icons-title-disc
    [article]="item"
    *ngIf="item.id == 271 && item.hidden == 0 && item.block == 1"
    id="271"
  ></app-icons-title-disc>
  <!-- title desc dynamic added -->
  <app-cards-with-search
    [article]="item"
    *ngIf="item.id == 272 && item.hidden == 0 && item.block == 1"
    id="272"
  ></app-cards-with-search>
  <!-- title desc dynamic added -->
  <app-image-title
    [article]="item"
    *ngIf="item.id == 276 && item.hidden == 0 && item.block == 1"
    id="276"
  ></app-image-title>
  <!-- title desc dynamic added -->

  <app-about-tabs-image
    [article]="item"
    *ngIf="item.id == 277 && item.hidden == 0 && item.block == 1"
    id="277"
  ></app-about-tabs-image>
  <!-- title desc dynamic added -->
  <app-benfits-with-cards
    [article]="item"
    *ngIf="item.id == 278 && item.hidden == 0 && item.block == 1"
    id="278"
  ></app-benfits-with-cards>
  <!-- title desc dynamic added -->
  <app-reservation-form4
    [article]="item"
    *ngIf="item.id == 261 && item.hidden == 0 && item.block == 1"
    id="261"
  ></app-reservation-form4>
  <!-- title desc dynamic added -->
  <app-image-slider3 
  [article]="item"
    *ngIf="item.id == 283 && item.hidden == 0 && item.block == 1"
    id="283"></app-image-slider3>
  <!-- title desc dynamic added -->

  <app-get-touch
  [article]="item"
    *ngIf="item.id == 2399 && item.hidden == 0 && item.block == 1"
    id="2399"
   ></app-get-touch>
  <!-- title desc dynamic added -->

  <app-title-desc-image3
  [article]="item"
    *ngIf="item.id == 2398 && item.hidden == 0 && item.block == 1"
    id="2398"
  ></app-title-desc-image3>
  <!-- <app-card-profile></app-card-profile> -->
    
  <!-- title desc dynamic added -->
  <app-how-to-image-desc [article]="item"
  *ngIf="item.id == 2397 && item.hidden == 0 && item.block == 1"
  id="2397"
  ></app-how-to-image-desc>
<!-- <app-separator></app-separator> -->

  <!-- <app-contactus7></app-contactus7> -->

</ng-template>
