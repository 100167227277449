<div [appMyStyles]="article">

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
>
<app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div
  class="row"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  (click)="navigateToShow(ImageTitleDescriptionData.id)"
>
  <div class="col-md-5 col-sm-12 col-xs-12" style="padding: 0px">
    <img class="img-style" style=" height: 300px;" src="{{ ImageTitleDescriptionData?.image }}" />
    <!-- <iframe
      class="img-style"
      style="height: 300px"
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(vide0_Link))
      "
    ></iframe> -->
  </div>

  <div
    class="col-md-7 col-sm-12 col-xs-12"
    style="background: rgba(83 , 134 , 197 , 0.16); padding: 30px"
  >
    <h1 class="sub-title" >{{ImageTitleDescriptionData.title}}</h1>

    <p class="paragraph" [innerHTML]="ImageTitleDescriptionData?.content"></p>

    <p class="paragraph">{{ImageTitleDescriptionData.description}}</p>
  </div>
</div>
</div>