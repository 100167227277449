<div
  [appMyStyles]="component"
  *ngIf="component.main_id != 20622"
>
    <app-header-title-desc [article]="component"></app-header-title-desc>
</div>

<div [appMyStyles]="component" *ngIf="component.main_id == 20622">
  <app-header-title-desc [article]="component"></app-header-title-desc>


  <div
    class="row d-flex align-items-center m-1"
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    (click)="navigateToShow(imageTitleDescriptionData?.id)"
  >
    <div
      *ngIf="component.image_position == 'left'"
      class="col-md-4 col-sm-12 col-xs-12 shadow d-flex align-items-center justify-content-center border_color mx-3"
    >
      <img
        class="program-img  my-3"
        src="{{ imageTitleDescriptionData?.image }}"
      />
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12">
      <h4
        *ngIf="lang == 'en_container'"
        class="compoenet-title"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="compoenet-title"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <div class="lines">
        <div class="line"></div>
      </div>
      <!-- <h3
          class="main-title "
          *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
          [innerHtml]="imageTitleDescriptionData?.title_detailed"
        ></h3> -->
      <p
        class="main-paragraph"
        *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
        [innerHtml]="imageTitleDescriptionData?.description_detailed"
      ></p>

      <h3 class="main-title" *ngIf="component.show_cms_title == 1 && component.text_as_html == 0">
        {{ imageTitleDescriptionData?.title }}
      </h3>
      <p
        class="main-paragraph"
        *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
      >
        {{ imageTitleDescriptionData?.description }}
      </p>
    </div>

    <div *ngIf="component.image_position == 'right'" class="col-md-4 col-sm-12 col-xs-12">
      <img class="program-img2" src="{{ imageTitleDescriptionData?.image }}" />
    </div>
  </div>
</div>

<div [appMyStyles]="component" >
  

  <div
    class="row mx-4 "
    [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    (click)="navigateToShow(imageTitleDescriptionData?.id)"
  >
    <div
      *ngIf="component.image_position == 'left'"
      class="col-md-4 col-sm-12 col-xs-12"
    >
      <img
        class="program-img my-3"
        src="{{ imageTitleDescriptionData?.image }}"
      />
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12 padd">



      <p [ngStyle]="{
        'font-size': component.title_size,
         color: component.title_color
      }">{{ imageTitleDescriptionData?.title }}</p>
      
      <p [ngStyle]="{
        'font-size': component.description_size,
         color: component.description_color
      }">
        {{ imageTitleDescriptionData?.description }}
      </p>
    </div>

    <div *ngIf="component.image_position == 'right'" class="col-md-4 col-sm-12 col-xs-12">
      <img class="program-img2" src="{{ imageTitleDescriptionData?.image }}" />
    </div>

  </div>
</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="imageTitleDescriptionData?.length == 0">
  <p>this design need some data to be visible  </p>
</div>
