<div class="row" *ngIf="APP_ID != 360" 
     [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>
</div>

<div class="row" *ngIf="APP_ID == 360" style="margin-top: -30px" 
      [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>
</div>

<div *ngIf="main_id != 16023"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"

  class="row parralax"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"
  [appMyStyles]="article"
>
  <div
    class="col-md-12 col-sm-12 col-xs-12 general title-btn"
    *ngIf="main_id != 17451 && main_id !=16023"
  >
    <h2
     class="title-center"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      *ngIf="component_title_position == 'center'"
    >
      {{ ComponentTitle }}
    </h2>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>

    <p
      style="
        text-align: center;
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
      "
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></p>

    <p
      style="
        text-align: center;
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
      "
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </p>

    <p
      style="
        text-align: center;
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
      "
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></p>

    <p
      style="
        text-align: center;
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
      "
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </p>

    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="navigateToShow(Adertising_Data[0].id)"
      *ngIf="main_id == 14460"
      [ngStyle]="{
        color: Button_Color
      }"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
      style="
        background: linear-gradient(89.88deg, #e7cf85 0.72%, #003e27 103.9%);
      "
    ></button>

    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="navigateToShow(Adertising_Data[0].id)"
      *ngIf="main_id != 14460"
      [ngStyle]="{
        'background-color': article.component_buttons[0]?.button_background_color,
        color: article.component_buttons[0]?.button_color
      }"
    > {{article.component_buttons[0]?.button_text}}</button>
  </div>

  

  <div class="col-md-12 col-sm-12 col-xs-12 general2" *ngIf="main_id == 16023">
    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </p>

    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="navigateToShow(Adertising_Data[0].id)"
      style="
          color: #ffffff;
          border:1px solid #ffffff;
          background-color: transparent;
      "
    >
      {{ "Get quotation for sewing machines" | translate }}
    </button>
  </div>

  <div class="col-md-12 col-sm-12 col-xs-12 general2" *ngIf="main_id == 17451">
    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </p>

    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="navigateToShow(Adertising_Data[0].id)"
      [ngStyle]="{
        'background-color': Button_Background_Color,
        color: Button_Color
      }"
    >
      {{ "Book Now" | translate }}
    </button>
  </div>
</div>


<div *ngIf="main_id == 16023"
  class="row parralax general-3"
  [ngStyle]="{ 'background-image': 'url(' + Adertising_Data[0]?.image + ')' }"

>
  

  <div class="col-md-7 col-sm-12 col-xs-12" 
  [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.title_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.title }}
    </p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="Adertising_Data[0]?.description_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ Adertising_Data[0]?.description }}
    </p>

    

  </div>

  <div class="col-md-5 col-sm-12 col-xs-12 " >
  </div>

  <div class="col-md-12 col-sm-12 col-xs-12 " style="text-align: center;"
  [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
  >
    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="OpenPopup(Adertising_Data[0].id)"
      style="
          color: #ffffff;
          border:1px solid #ffffff;
          background-color: transparent;
      "
    >
      {{ "Get quotation for sewing machines" | translate }}
    </button>
  </div>

 
</div>
