<div [appMyStyles]="article">

  <app-header-title-desc [article]="article"></app-header-title-desc>

    <div class="row">

        <div  class="col-md-12 col-sm-12 col-xs-12"     
             *ngFor="let gp of Faq_Data; index as g" id="g_{{ g }}"
        >
      
            <div class="separator-short"></div>
            <div
              class="accordion"
            >
              <div class="accordion-item" (click)="showAnswer(g, g)">
                <div class="accordion-order"></div>
                <span class="target-fix" id="accordion-2-1"></span>
                <i class="fa fa-angle-down icon" aria-hidden="true"></i>
                <a id="open-accordion-2-1" title="open" style="font-weight: bold" [innerHtml]="gp.title_detailed">
                </a>

              </div>
              <span  class="display--none">
                  <p style="margin-bottom: 5px" [innerHtml]="gp.description_detailed"></p>
                  <hr
                    style="
                      margin-top: 0px;
                      margin-bottom: 0px;
                      border-top: 1px dashed#eee;
                    "
                  />
              </span>
            </div>
        </div>

    </div>





</div>