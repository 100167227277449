<div class="row">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div class="row" [appMyStyles]="component">
  <div
    class="col-md-4 col-sm-12 col-xs-12"
    [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
    style="margin-bottom: 15px"
    *ngFor="let item of threeCardsData | slice : 0 : component.items_number"
  >
    <div class="home-article hover-style" (click)="navigateToShow(item?.id)">
      <a
        *ngIf="item?.type == 'image'"
        class="cover-img"
        [ngStyle]="{ 'background-image': 'url(' + item?.image + ')' }"
      >
        <!-- <iframe
                        *ngIf="item?.type  != 'image' "
                         [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item?.link))"
                         width="100%"
                         height="250px"
                         frameborder="0"
                         title="test"
                         webkitallowfullscreen
                         mozallowfullscreen
                         allowfullscreen
                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe> -->

        <div class="home-article-desc text-left">
          <h4
            class="card-title"
            *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
            [innerHtml]="item?.title_detailed"
          ></h4>
          <h4 class="card-title" *ngIf="component.show_cms_title == 1 && component.text_as_html == 0">
            {{ item?.title }}
          </h4>

          <!-- <small class="p-date">{{item?.start_date}}</small> -->
          <p
            class="card-paragraph"
            *ngIf="component.show_cms_description== 1 && component.text_as_html == 1"
            [innerHtml]="item?.description_detailed"
          ></p>
          <p
            class="card-paragraph"
            *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
          >
            {{ item?.description }}
          </p>
        </div>
      </a>
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert"  *ngIf="threeCardsData?.length == 0">
    <p>this design need some data to be visible  </p>
  </div>
  
</div>
