import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ChangeHeaderFooterService } from "../shared_services/change-header-footer.service";

@Component({
  selector: "app-edit-component-popup",
  templateUrl: "./edit-component-popup.component.html",
  styleUrls: ["./edit-component-popup.component.scss"],
})
export class EditComponentPopupComponent implements OnInit {
  visible: boolean = true;

  selectedId = Number(JSON.parse(localStorage.getItem("Header_Layout")));

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private changeAllComponentsService: ChangeHeaderFooterService
  ) {

  }
  type: string = "";
  headerFooterDesigns = [];
  componentDesigns = [];
  item;
  selectedImageIndex: number | null = null;
  ngOnInit(): void {
    this.type = this.config.data.type;
    if (this.type == "header" || this.type == "footer") this.listHeader();
    if (this.type == "component" && !this.config.data.cached)
      {
        this.listComponentDesign();
      } 
      else if (this.type == "component" && this.config.data.cached)
      {
        this.componentDesigns=this.changeAllComponentsService.getComponentDesignList();
      }

  }

  // Method to handle image click
  selectImage(id: number): void {
    this.selectedId = id;
  }

  // Method to check if an image is selected
  isSelected(id: number): boolean {
    return this.selectedId === id;
  }
  listHeader() {
    const formData: FormData = new FormData();

    formData.append(this.type, "1");

    this.changeAllComponentsService
    .listHeaderAndFooter(formData)
    .subscribe((res: any) => {
      this.headerFooterDesigns = res.content;
      console.log(this.headerFooterDesigns);
      
    });
  }
  listComponentDesign() {

    let body = {
      menu_type_id: 4,
      blacklist_entity_id: 172,
      shape: "",
      component_style: "",
      category_id: "",
    };
    this.changeAllComponentsService.listComponentDesign(body).subscribe((res: any) => {
      this.componentDesigns = res.content;
      if(res.content)
      {
        this.changeAllComponentsService.setComponentDesignList(res.content);
      }
      console.log(this.componentDesigns);
    });
  }
  changeComponent() {
    this.item = this.config.data.item;
    let body = {
      application_id: this.item.parameter.application_id,
      component_id: this.item.main_id,
      module_id: this.item.parameter.module_id,

      coreComponent_id: this.selectedId,

      category_id: this.item.parameter.category_id,

      menu_item_id: this.item.menuItem_id,
    };

    this.changeAllComponentsService.changeComponent(body).subscribe((res: any) => {
      if (res.status.code == "200") {
        this.changeAllComponentsService.clearCache().subscribe((res: any) => {});
        this.ref.close(res.content);
      }
    });
  }
  confirm() {
    if (this.type != "component") {
      let body;
      if (this.type == "header") {
        body = { header_id: this.selectedId };
      } else if (this.type == "footer") {
        body = { footer_id: this.selectedId };
      }
      this.changeAllComponentsService
      .changeHeaderAndFooter(body)
      .subscribe((res: any) => {
        if (res.status.code == "200") {
          this.changeAllComponentsService.clearCache().subscribe((res: any) => {});
          this.ref.close(this.selectedId);
        }
      });
    } else {
      this.changeComponent();
    }
  }
}
