<div class="container" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div *ngIf="gridCardsData?.length>0"
    class="row"
    [class]="article?.animations?.length > 1 ? article?.animations[0] : ''"
  >
    <div class="col-md-6 col-sm-6 col-xs-12">
      <div class="card" (click)="navigateToShow(gridCardsData[0].id)">
        <div
          *ngIf="gridCardsData[0].type == 'image'"
          class="card-img-top"
          [style]="{
            'background-image': 'url(' + gridCardsData[0].image + ')'
          }"
        ></div>
        <!-- <iframe
            *ngIf="gridCardsData[0].type  != 'image' "
            [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(gridCardsData[0]?.link))"
            width="100%"
             height="300px"
            frameborder="0"
            title="test"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>   -->

        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="event-desc text-left">
                <h4
                  class="card-title"
                  *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                  [innerHtml]="gridCardsData[0].title_detailed"
                ></h4>
                <p
                  class="card-paragraph"
                  *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                  [innerHtml]="gridCardsData[0].description_detailed"
                ></p>

                <h4
                  class="card-title"
                  *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                >
                  {{ gridCardsData[0].title }}
                </h4>
                <p
                  class="card-paragraph"
                  *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                >
                  {{ gridCardsData[0].description }}
                </p>

                <p [innerHTML]="gridCardsData[0].content"></p>
                <a class="more">
                  {{ "view more" | translate }}
                  <i class="fa fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
      <div class="row">
        <div
          *ngFor="let news of gridCardsData | slice : 1 : 5"
          class="col-md-6 col-sm-6 col-xs-12"
          (click)="navigateToShow(news.id)"
        >
          <div class="card card2">
            <div
              *ngIf="news.type == 'image'"
              class="card-img-top"
              [style]="{ 'background-image': 'url(' + news.image + ')' }"
            ></div>
            <!-- <iframe
                      *ngIf="news.type  != 'image' "
                      [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(news?.link))"
                      width="100%"
                      height="300px"
                      frameborder="0"
                      title="test"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>   -->
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="event-desc text-left">
                    <h4
                      class="card-title"
                      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
                      [innerHtml]="news.title_detailed"
                    ></h4>
                    <p
                      class="card-paragraph"
                      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
                      [innerHtml]="news.description_detailed"
                    ></p>

                    <h4
                      class="card-title"
                      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
                    >
                      {{ news.title }}
                    </h4>
                    <p
                      class="card-paragraph"
                      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
                    >
                      {{ news.description }}
                    </p>

                    <a class="more">
                      {{ "view more" | translate }}
                      <i class="fa fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="card card2">
                <div class="card-img-top" style="background-image: url(assets/images/news1.jpeg);"></div>
                <div class="card-body ">
                  <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="event-desc text-left">
                        <h4 class="card-title">
                          الجمعية تجهز سلة وقائية لتوزيعها على مرضى الفشل الكلوي
                        </h4>

                        <a class="more" routerLink="/articles/727">
                          إقرأ المزيد
                          <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="card card2">
                <div class="card-img-top" style="background-image: url(assets/images/logo2.png);"></div>
                <div class="card-body ">
                  <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="event-desc text-left">
                        <h4 class="card-title">
                          “كلانا” تنفّذ 78 ألف جلسة “غسيل كلى” سنويًّا بتكلفة 43 مليون ريال </h4>

                        <a class="more" routerLink="/articles/732">
                          إقرأ المزيد
                          <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div> -->
      </div>
      <!-- <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="card card2">
                <div class="card-img-top" style="background-image: url(assets/images/latestnews5.jpeg);"></div>
                <div class="card-body ">
                  <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="event-desc text-left">
                        <h4 class="card-title">
                          «هدف» و «كلانا» يبرمان اتفاقية تعاون للتدريب والتوظيف </h4>

                        <a class="more" routerLink="/articles/716">
                          إقرأ المزيد
                          <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="card card2">
                <div class="card-img-top" style="background-image: url(assets/images/latestnews4.jpeg);"></div>
                <div class="card-body ">
                  <div class="row">

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="event-desc text-left">
                        <h4 class="card-title">
                          بمناسبة اليوم العالمي للكلى وفد من شركة ميدغلف يقوم بزيارة جمعية كلانا </h4>

                        <a class="more" routerLink="/articles/729">
                          إقرأ المزيد
                          <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div> -->
    </div>
  </div>
  <div class="alert alert-warning text-center" role="alert" *ngIf="gridCardsData?.length == 0">
    <p>this design need some data to be visible </p>
  </div>
</div>
