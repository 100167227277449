<header class="" [ngStyle]="{ 'background-color': Header_Bg_Color }" >
  <div class="full-width">
    <nav class="navbar navbar-expand-lg navbar-light"> 
      <a class="navbar-brand" *ngIf="this.APP_ID != 202 ">
        <img class="logo" [src]="website_logo" />
      
      </a>
      <a class="navbar-brand" *ngIf="this.APP_ID == 202 ">
        <img class="logo2" [src]="website_logo" />
    
      </a>
      <div class="mobile-only pull-right">
        <div class="nav-item dropdown pull-right" *ngIf="token != null">
          <a
            class="nav-link notification-button"
            href="#"
            id="notification"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-bell"></i>
            <span class="num">{{ notification_count | numberFormatter }}</span>
          </a>
          <div
            class="dropdown-menu notification-list"
            aria-labelledby="notification"
          >
            <li *ngFor="let notificationItem of notificationList">
              <a
                class="nav-link dropdown-item {{
                  notificationItem.read_at ? 'notification-read' : ''
                }}"
                routerLink="user-area/orders"
                id="notifi"
                (click)="readselected(notificationItem.id)"
              >
                <span class="float-right">
                  {{ notificationItem.notification_text }}
                </span>

                <small class="float-right time">
                  {{ notificationItem.since }}
                </small>
              </a>
            </li>
          </div>
        </div>
        <div class="searchbar pull-right" *ngIf="APP_ID != null">
          <span class="search-button">
            <div
              [ngStyle]="{
                'background-color': Header_Bg_Color,
                'border-color': Header_Bg_Color
              }"
              class="nav-link bold search-toggle bold"
              aria-hidden="true"
              (click)="clickEvent()"
            >
              <i class="fa fa-search"></i>
            </div>
          </span>
          <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
            <div class="container">
              <input
                type="text"
                (keyup.enter)="search()"
                placeholder="{{ 'search' | translate }}"
                [(ngModel)]="searchQuery"
                [ngModelOptions]="{ standalone: true }"
              />
              <button
                [ngStyle]="{
                  left: lang.key == 'en' ? '8%' : 'unset',
                  right: lang.key == 'ar' ? '8%' : 'unset'
                }"
                [ngStyle]="{ 'background-color': Header_Bg_Color }"
                class="search-btn"
                (click)="search()"
              >
                {{ "search" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
      [ngStyle]="{
        background: Header_Color
      }"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
      *ngIf="APP_ID != 384 && APP_ID != 202"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

     

      <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
        style="background: white;"
        *ngIf="APP_ID == 384 || APP_ID == 202"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown" *ngIf="APP_ID != 384 && APP_ID != 360 && APP_ID != 202">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
            <li
              class="nav-item"
              [ngStyle]="{ 'margin-inline': '0.5rem' }"
              *ngIf="item.menu_design.header == 1"
            >
              <a
                (mouseover)="hoverHeaderItem = i"
                (mouseleave)="hoverHeaderItem = -1"
                [ngStyle]="{
                  color: Header_Color,
                 'font-size' : Header_font_size ,
                  'white-space': 'nowrap',
                  'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight,
                  'border-bottom':
                    hoverHeaderItem == i || itemSelectedFromHeader == item.name
                      ? '2px solid' + Header_Color
                      : 'none'
                }"
                class="nav-link"
                [routerLinkActive]="'active-link'"
                (click)="navigatetoModule(item)"
                >{{ item.name }}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>

      
      <div class="collapse navbar-collapse car-padd" id="navbarNavDropdown" *ngIf="APP_ID == 384 ">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
            <li
              class="nav-item"
              [ngStyle]="{ 'margin-inline': '0.5rem' }"
              *ngIf="item.menu_design.header == 1"
            >
              <a
                (mouseover)="hoverHeaderItem = i"
                (mouseleave)="hoverHeaderItem = -1"
                [ngStyle]="{
                  color: Header_Color,
                 'font-size' : Header_font_size ,
                  'white-space': 'nowrap',
                  'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight,
                  'border-bottom':
                    hoverHeaderItem == i || itemSelectedFromHeader == item.name
                      ? '2px solid' + Header_Color
                      : 'none'
                }"
                class="nav-link"
                [routerLinkActive]="'active-link'"
                (click)="navigatetoModule(item)"
                >{{ item.name }}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>

      <div class="collapse navbar-collapse car-padd2" id="navbarNavDropdown" *ngIf="APP_ID == 360 || APP_ID == 202">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
            <li
              class="nav-item"
              [ngStyle]="{ 'margin-inline': '0.9rem' }"
              *ngIf="item.menu_design.header == 1"
            >
              <a
                (mouseover)="hoverHeaderItem = i"
                (mouseleave)="hoverHeaderItem = -1"
                [ngStyle]="{
                  color: hoverHeaderItem == i || itemSelectedFromHeader == item.name ? websiteSecColor  : Header_Color,
                 'font-size' : Header_font_size ,
                  'white-space': 'nowrap',
                  'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight,
                  'border-bottom':
                    hoverHeaderItem == i || itemSelectedFromHeader == item.name
                      ? '2px solid' + websiteSecColor
                      : 'none'
                }"
                class="nav-link"
                [routerLinkActive]="'active-link'"
                (click)="navigatetoModule(item)"
                >{{ item.name }}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>

      <div
        class="collapse navbar-collapse lang-position"
        id="navbarNavDropdown"
        *ngIf="APP_ID != 384"
      >
        <ul class="navbar-nav">
          <!-- <li
            class="nav-item dropdown desktop-only"
            *ngIf="notification_count > 0"
          >
            <a
              [ngStyle]="{ 'background-color': Header_Bg_Color }"
              class="nav-link notification-button"
              href="#"
              id="notification"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-bell"></i>
              <span class="num">{{
                notification_count | numberFormatter
              }}</span>
            </a>
            <div
              class="dropdown-menu notification-list"
              aria-labelledby="notification"
            >
              <li *ngFor="let notification of notificationList">
                <a
                  class="nav-link dropdown-item  {{
                    notification.read_at ? 'notification-read' : ''
                  }}"
                  id="notifi"
                  (click)="readselected(notification)"
                >
                  <span class="float-right">
                    {{ notification.notification_text }}
                  </span>

                  <small class="float-right time">
                    {{ notification.since }}
                  </small>
                </a>
              </li>
            </div>
          </li> -->

          <!-- <div class="searchbar desktop-only" *ngIf="APP_ID != 202">
            <span class="search-button whiteColor">
              <div
                [ngStyle]="{
                  'background-color': websiteSecColor,
                  'border-color': websiteSecColor,
                  'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight,
                  'color':Header_Bg_Color
                }"
                class="nav-link bold search-toggle bold"
                aria-hidden="true"
                (click)="clickEvent()"
              >
              <i class="fa fa-search"></i>

              </div>
            </span>
            <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
              <div class="container">
                <input
                  type="text"
                  placeholder="{{ 'search' | translate }}"
                  [(ngModel)]="searchQuery"
                  [ngModelOptions]="{ standalone: true }"
                />
                <button
                  [ngStyle]="{
                    left: lang.key == 'en' ? '8%' : 'unset',
                    right: lang.key == 'ar' ? '8%' : 'unset'
                  }"
                  [ngStyle]="{ 'background-color': Header_Bg_Color , 'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight }"
                  class="search-btn-new"
                  (click)="search()"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div> -->

          <div class="dropdown">
            <button
              [ngStyle]="{
               
                  'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight,
                  'color':Header_Color
              }"
              class="btn  dropdown-toggle language-btn"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
            <i class="fa fa-globe" aria-hidden="true"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="changeLang('ar')">
                <img class="flag" src="assets/images/ar.png" />
                عربى
              </a>
              <a class="dropdown-item" (click)="changeLang('en')">
                <img class="flag" src="assets/images/en.jpg" />
                English
              </a>
            </div>
          </div>
          
        </ul>
      </div>

      <div
        class="collapse navbar-collapse lang-position"
        id="navbarNavDropdown"
        *ngIf="APP_ID == 384"
      >
        <ul class="navbar-nav">
          <li
            class="nav-item dropdown desktop-only"
            *ngIf="notification_count > 0"
          >
            <a
              [ngStyle]="{ 'background-color': Header_Bg_Color }"
              class="nav-link notification-button"
              href="#"
              id="notification"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-bell"></i>
              <span class="num">{{
                notification_count | numberFormatter
              }}</span>
            </a>
            <div
              class="dropdown-menu notification-list"
              aria-labelledby="notification"
            >
              <li *ngFor="let notification of notificationList">
                <a
                  class="nav-link dropdown-item  {{
                    notification.read_at ? 'notification-read' : ''
                  }}"
                  id="notifi"
                  (click)="readselected(notification)"
                >
                  <span class="float-right">
                    {{ notification.notification_text }}
                  </span>

                  <small class="float-right time">
                    {{ notification.since }}
                  </small>
                </a>
              </li>
            </div>
          </li>
          <div class="searchbar desktop-only" *ngIf="APP_ID != null">
            <span class="search-button whiteColor">
              <div
                [ngStyle]="{
                  'background-color': Header_Color,
                  'border-color': Header_Color,
                  'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight,
                  'color':Header_Bg_Color
                }"
                class="nav-link bold search-toggle bold"
                aria-hidden="true"
                (click)="clickEvent()"
              >
              <i class="fa fa-search"></i>

              </div>
            </span>
            <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
              <div class="container">
                <input
                  type="text"
                  placeholder="{{ 'search' | translate }}"
                  [(ngModel)]="searchQuery"
                  [ngModelOptions]="{ standalone: true }"
                />
                <button
                  [ngStyle]="{
                    left: lang.key == 'en' ? '8%' : 'unset',
                    right: lang.key == 'ar' ? '8%' : 'unset'
                  }"
                  [ngStyle]="{ 'background-color': Header_Bg_Color , 'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight }"
                  class="search-btn-new"
                  (click)="search()"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="dropdown">
            <button
              [ngStyle]="{
               
                  'text-transform': Text_Style,
                  'font-family':text_type,
                  'font-weight': text_weight,
                  'color':Header_Color
              }"
              class="btn  dropdown-toggle language-btn"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
            <i class="fa fa-globe" aria-hidden="true"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="changeLang('ar')">
                <img class="flag" src="assets/images/ar.png" />
                عربى
              </a>
              <a class="dropdown-item" (click)="changeLang('en')">
                <img class="flag" src="assets/images/en.jpg" />
                English
              </a>
            </div>
          </div>
          
        </ul>
      </div>


    </nav>
  </div>
</header>

<!-- <app-auth-layout
  *ngIf="mylogin"
  (closeLoginPopup)="closeLogin($event)"
></app-auth-layout> -->
