<header class="" [ngStyle]="{ 'background-color': Header_Bg_Color }">
  <div class="full-width">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" (click)="navigateToHome()">
        <img class="logo" [src]="website_logo"   />
        <!-- <img class="logo" src="assets/images/initiator/logo.PNG" /> -->
        <!-- <img
            *ngIf="website_logo == '' || website_logo == null"
            class="logo"
            src="assets/images/Appoxlogo.PNG"
          /> -->
        <!-- <img
            *ngIf="website_logo == '' || website_logo == null"
            src="assets/images/header3.PNG"
            style="width: 230px; height: 65px"
          /> -->
      </a>

      <div class="mobile-only pull-right">
        <!-- <div class="nav-item dropdown pull-right" *ngIf="token != null">
          <a
            class="nav-link notification-button"
            href="#"
            id="notification"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-bell"></i>
            <span class="num">{{ notification_count | numberFormatter }}</span>
          </a>
          <div
            class="dropdown-menu notification-list"
            aria-labelledby="notification"
          >
            <li *ngFor="let notificationItem of notificationList">
              <a
                class="nav-link dropdown-item {{
                  notificationItem.read_at ? 'notification-read' : ''
                }}"
                routerLink="user-area/orders"
                id="notifi"
                (click)="readselected(notificationItem.id)"
              >
                <span class="float-right">
                  {{ notificationItem.notification_text }}
                </span>

                <small class="float-right time">
                  {{ notificationItem.since }}
                </small>
              </a>
            </li>
          </div>
          
        </div> -->

        <div class="searchbar pull-right" *ngIf="APP_ID != null">
          <span class="search-button">
            <div
              class="nav-link bold search-toggle bold"
              aria-hidden="true"
              (click)="clickEvent()"
            >
              <i class="fa fa-search"></i>
            </div>
          </span>
          <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
            <div class="container">
              <input
                type="text"
                (keyup.enter)="search()"
                placeholder="{{ 'search' | translate }}"
                [(ngModel)]="searchQuery"
                [ngModelOptions]="{ standalone: true }"
              />
              <!-- <input type="button" value="بحث"/> -->
              <button
                [ngStyle]="{ 
                'background-color': Header_Bg_Color,
                 'text-transform': Text_Style,
                'font-family':text_type,
                'font-weight': text_weight }"
                [ngStyle]="{
                  left: lang.key == 'ar' ? '8%' : 'unset',
                  right: lang.key == 'en' ? '8%' : 'unset'
                }"
                class="search-btn"
                (click)="search()"
              >
                {{ "search" | translate }}
              </button>
              <!-- <button *ngIf="isHidden == false " class="cancel-btn2"  (click)="ResetSearch()"> <i class="fa fa-times"></i>  </button> -->
            </div>
          </div>
        </div>
      </div>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let item of Menu_items_sorted; let i = index">
            <a
              (mouseover)="hoverHeaderItem = i"
              (mouseleave)="hoverHeaderItem = -1"
              *ngIf="
                item.menu_design.header == 1 
              "
              [ngStyle]="{
                 color: Header_Color,
                'font-size' : Header_font_size ,
                'text-transform': Text_Style ,
                'font-family':text_type,
                'font-weight': text_weight,
                'white-space': 'nowrap',
                'border-bottom':
                  hoverHeaderItem == i || itemSelectedFromHeader == item.name
                    ? '2px solid' + Header_Color
                    : 'none'
              }"
              class="nav-link"
              [routerLinkActive]="'active-link'"
              (click)="navigatetoModule(item)"
              >{{ item.name }}</a
            >
          </li>

         
        </ul>
      </div>

      <div
        class="collapse navbar-collapse lang-position"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav">
          <div class="searchbar desktop-only" *ngIf="APP_ID != null">
            <div class="">
              <input
                class="search-new"
                type="text"
                placeholder="{{ 'search' | translate }}"
                [(ngModel)]="searchQuery"
                [ngModelOptions]="{ standalone: true }"
              />
              <!-- <input type="button" value="بحث"/> -->
              <button
                [ngStyle]="{
                  left: lang.key == 'ar' ? '25%' : 'unset',
                  right: lang.key == 'en' ? '20%' : 'unset'
                }"
                class="search-btn-new"
                (click)="search()"
              >
                <i class="fas fa-search"></i>
              </button>
              <!-- <button *ngIf="isHidden == false " class="cancel-btn2"  (click)="ResetSearch()"> <i class="fa fa-times"></i>  </button> -->
            </div>
          </div>

          <li
            class="search-toggle"
            *ngIf="lang.key == 'ar'"
            (click)="changeLang('en')"
          >
            <a class="d-flex" 
            [ngStyle]="{
              color: Header_Color,
             'font-size' : Header_font_size ,
             'text-transform': Text_Style ,
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> English </a>
          </li>
          <li
            class="search-toggle"
            *ngIf="lang.key == 'en'"
            (click)="changeLang('ar')"
          >
            <a class="d-flex" [ngStyle]="{
              color: Header_Color,
             'font-size' : Header_font_size ,
             'text-transform': Text_Style ,
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> عربى </a>
          </li>

          <!-- <div class="dropdown">
              <button
                [ngStyle]="{
                  'background-color': websiteMainColor,
                  'border-color': websiteMainColor
                }"
                class="btn btn-secondary dropdown-toggle language-btn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ "language" | translate }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="changeLang('ar')">
                  <img class="flag" src="assets/images/ar.png" />
                  عربى
                </a>
                <a class="dropdown-item" (click)="changeLang('en')">
                  <img class="flag" src="assets/images/en.jpg" />
                  English
                </a>
              </div>
            </div> -->

          <!-- <li class="nav-item" *ngIf="APP_ID != null ">
                          <a class="search-button whiteColor" style="border-radius: 0px;padding: 4px 25px;cursor: pointer;" [ngStyle]="{'background-color':websiteMainColor,'border-color': websiteMainColor}"  (click)="Logout()" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">
                               {{'Exist'|translate}}
                          </a>
                      </li> -->

          <!-- <select class="form-control">
                          <option> 
                              <img class="flag"  src="assets/images/ar.png">
                          </option>
                          <option>
                               <img class="flag"  src="assets/images/en.jpg">
                          </option>
  
                      </select> -->

          <!-- <mat-select>
                          <mat-option>
                            <img class="flag" src="assets/images/ar.png"> عربى
                          </mat-option>
                          <mat-option  >
                              <img class="flag" src="assets/images/en.jpg"> English
                            </mat-option>
                        </mat-select> -->

          <!-- <li class="nav-item" style="margin-top: -7px;" *ngIf="lang =='en_container'" (click)="changeLanguage('ar_container')">
                          <a [ngStyle]="{'color':websiteMainColor}" class="nav-link d-flex lang" style="font-size: 17px;">
                            <i [ngStyle]="{'color':websiteMainColor}" class="fa fa-globe lang-icon"></i>
                           عربى
                          </a>
                      </li> -->

          <!-- <li class="nav-item" style="margin-top: -7px;" *ngIf="lang =='ar_container'" (click)="changeLanguage('en_container')">
                          <a [ngStyle]="{'color':websiteMainColor}" class="nav-link d-flex lang" style="font-size: 17px;">
                            <i [ngStyle]="{'color':websiteMainColor}" class="fa fa-globe lang-icon"></i>
                           English
                          </a>
                      </li> -->
        </ul>
      </div>
    </nav>
  </div>
</header>

<app-auth-layout
  *ngIf="mylogin"
  (closeLoginPopup)="closeLogin($event)"
></app-auth-layout>
