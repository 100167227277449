<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    class="row justify-content-center align-items-center"
  >
    <div
      class="m-2 col-2 d-flex flex-column justify-content-center align-items-center"
      *ngFor="let item of ImageTitleData | slice : 0 : article.items_number"
    >
      <div class="icon rounded-circle">
        <img src="{{ item.image }}" />
      </div>
      <p
        class="my-2"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="item.title_detailed"
      ></p>
    </div>
  </div>
</div>
