<div class="row footer" [ngStyle]="{ 'background-color': websiteMainColor }">
  <div class="col-md-2 col-sm-2 col-xs-2"></div>
  <div class="col-md-8 col-sm-8 col-xs-8 text-center">
    <div class="social">
      <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
        <li class="list-inline-item" *ngFor="let link of SocialLinks">
          <a
            *ngIf="link.value != null"
            class="btn-floating btn-sm rgba-white-slight"
            href="{{ link.value }}"
            target="_blank"
          >
            <i
              *ngIf="link.type.name == 'whatsapp'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fab fa-whatsapp"
            ></i>
            <i
              *ngIf="link.type.name == 'facebook'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fa fa-facebook"
            ></i>
            <i
              *ngIf="link.type.name == 'twitter'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fa fa-twitter"
            ></i>
            <i
              *ngIf="link.type.name == 'youtube'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fab fa-youtube"
              style="padding: 10px 8px !important"
            ></i>
            <i
              *ngIf="link.type.name == 'instagram'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fa fa-instagram"
            ></i>
            <i
              *ngIf="link.type.name == 'snapchat'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fa fa-snapchat"
            ></i>
            <i
              *ngIf="link.type.name == 'telegram'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fab fa-telegram-plane"
            ></i>
            <i
              *ngIf="link.type.name == 'linkedin'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fab fa-linkedin-in"
            ></i>
            <i
              *ngIf="link.type.name == 'vimeo'"
              [ngStyle]="{
                color: websiteMainColor,
                'background-color': websiteSecColor
              }"
              class="fab fa-vimeo-v"
            ></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="">
      <ul class="edit">
        <li
          *ngFor="let item of MenuItems"
          class="list-item"
          (click)="navigatetoModule(item)"
        >
          <a *ngIf="item.menu_design.footer == 1">{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-2 col-sm-2 col-xs-2"></div>
</div>
<div class="row footer2" [ngStyle]="{ 'background-color': websiteSecColor }">
  <div class="col-lg-12">
    <p class="text-center copy-right" style="color: white">
      <a class="footer-link">{{ "FOOTER.COPY_RIGHT" | translate }}</a>
    </p>
  </div>
</div>
